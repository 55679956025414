/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import AddLabelModal from "components/Common/Modal/AddLabelModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import CrmNoteHeader from "./Header/CrmNoteHeader";
import SaveNotePopup from "./Popup/SaveNotePopup";
import AddNewNotePopup from "./Popup/AddNewNotePopup";
import SaveDirectAccessModal from "components/Common/Modal/SaveDirectAccessModal";

const CrmNoteListBody = () => {
  const moduleSlug = "opportunity";

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  const todayValue = new Date().toISOString().split("T")[0];
  const moduleAccess = localStorage.getItem("moduleaccess");
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  const [isLoading, setIsLoading] = useState(false);
  const [noteList, setNoteList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedNoteIds, setSelectedNoteIds] = useState([]);

  const [selectedNoteId, setSelectedNoteId] = useState(null);

  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterTitle, setfilterTitle] = useState("");
  const [filterDetails, setfilterDetails] = useState("");
  const [filterLead, setfilterLead] = useState(null);
  const [filterContact, setfilterContact] = useState(null);
  const [filterInvoice, setfilterInvoice] = useState(null);
  const [filterTask, setfilterTask] = useState(null);

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  // paginatin ends -----------------------------------

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all contacts
  const getNoteList = async () => {
    setRowSelection({});
    setSelectedNoteIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CRM_NOTE +
        `?token=${token}&filternotetype=1`;

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestUrl += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      if (filterTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }

      if (filterDetails != "") {
        requestUrl = requestUrl + `&filtertitle=${filterDetails}`;
      }

      if (filterLead) {
        requestUrl += `&leadid=${filterLead}`;
      }

      if (filterContact) {
        requestUrl += `&contactid=${filterContact}`;
      }

      if (filterInvoice) {
        requestUrl += `&invoiceid=${filterInvoice}`;
      }

      if (filterTask) {
        requestUrl += `&taskid=${filterTask}`;
      }

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setNoteList(response.data);
        // resetFilterData();
        setMessageType("success");
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change status
  const changeStatusHandler = async () => {
    let responseArr = [];
    for (let selectedId of selectedNoteIds) {
      try {
        let invoiceData = {
          status: "0",
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_INVOICE +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, invoiceData);

        if (response.status) {
          responseArr.push(true);
          setMessageType("success");
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    }

    if (responseArr.length > 0) {
      setTimeout(() => {
        getNoteList();
      }, 1500);
    }
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Action"),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="offcanvas"
          data-bs-target="#saveNote"
          aria-controls="saveNote"
          onClick={() => {
            setSelectedNoteId(row.original._id);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
    },
    {
      accessorKey: "notetitle",
      header: t("Title"),
      size: 200,
    },
    {
      accessorKey: "moduleslug",
      header: t("Module"), //lead title
      size: 250,
    },
    {
      accessorKey: "componentname",
      header: t("Component"), //lead,contact
      size: 250,
    },
    {
      accessorKey: "notedate",
      header: t("Date"),
      size: 200,
    },
    {
      accessorKey: "ownerofcomponent",
      header: t("Owner Element"),
      size: 200,
    },
    {
      accessorKey: "moderatorname",
      header: t("Moderator"),
      size: 200,
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      size: 250,
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
    },
  ];

  //* Material React Table Column and States */
  const columns = useMemo(() => initialTableColumns, [i18n.language]);

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const resetFilterData = () => {
    setfilterStartDate("");
    setfilterEndDate("");
    setfilterTitle("");
    setfilterDetails("");
    setfilterLead(null);
    setfilterContact(null);
    setfilterInvoice(null);
    setfilterTask(null);

    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });

    getNoteList();
  };

  //   useEffect(() => {
  //     if (
  //       filterStartDate !== "" ||
  //       filterEndDate !== "" ||
  //       filterStartingPrice !== "" ||
  //       filterEndingPrice !== "" ||
  //       filterOwners.length > 0 ||
  //       filterFollowers.length > 0 ||
  //       filterTags.length > 0 ||
  //       filterPromisePercent !== "" ||
  //       filterTitle !== ""
  //     ) {
  //       getNoteList();
  //     }
  //   }, [
  //     filterStartDate,
  //     filterEndDate,
  //     filterStartingPrice,
  //     filterEndingPrice,
  //     filterOwners,
  //     filterFollowers,
  //     filterTags,
  //     filterPromisePercent,
  //     filterTitle,
  //   ]);

  useEffect(() => {
    getNoteList();
  }, []);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedNoteIds(selectedIdsArray);
    } else {
      setSelectedNoteIds([]);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (pagination.pageIndex > 0 || pagination.pageSize > 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption={t("Notes")}
        />
        <div className="container-fluid px-lg-5">
          <CrmNoteHeader reloadList={resetFilterData} />
          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={noteList} // data from api to be displayed
                positionActionsColumn="last"
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{
                  rowSelection,
                  pagination: lastPagination,
                  columnOrder,
                }} //pass our managed row selection state to the table to use
                onPaginationChange={setPagination} // set pagination
                defaultColumn={{
                  minSize: 20,
                  maxSize: 200,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>

      {/* create new note in any component */}
      <AddNewNotePopup
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterPopupClose={() => {
          setTimeout(() => {
            getNoteList();
          }, 2500);
        }}
      />

      {/* update or add new note in same component */}
      <SaveNotePopup
        selectedNoteId={selectedNoteId}
        setSelectedNoteId={setSelectedNoteId}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        afterPopupClose={() => {
          setTimeout(() => {
            getNoteList();
          }, 2500);
        }}
      />

      <AddLabelModal
        moduleName="crmnote"
        selectedIds={selectedNoteIds}
        afterTagModalClose={() => {
          setTimeout(() => {
            getNoteList();
          }, 2500);
        }}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <SaveDirectAccessModal
        moduleSlug="MOD_CRM_NOTES"
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default CrmNoteListBody;
