/* eslint-disable */
import React, { useEffect, useContext, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Select from "react-select";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import VatOptions from "data/Invoice/VAT.json";

import * as url from "helper/UrlHelper";
import { deleteData, getData, postData, putData } from "utils/Gateway";

import {
  ecosystemSlug,
  invoiceOwnerEmail,
  invoiceBillingAddress,
} from "Config/Config";

//import translation function for language translation
import { useTranslation } from "react-i18next";

//import current date
import { getCurrentDateInString } from "helper/getcurrentdatestring";
import { assetImages } from "constants";
import NoteSection from "components/Common/NoteComponent/NoteSection";
import SaveInvoiceSuccessModal from "components/Common/Modal/SaveInvoiceSuccessModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const InvoiceDetailsBody = () => {
  const token = localStorage.getItem("token");
  const ecosystemLogo = localStorage.getItem("ecosystemlogo");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); //for translation
  const history = useHistory();
  const params = useParams();

  const breadcrumbText = [
    { title: t("Invoice"), link: "/admin/invoice/list" },
    { title: t("Save/Details") },
  ];

  const [clientList, setClientList] = useState([]); //client option

  const [invoiceIdNumber, setinvoiceIdNumber] = useState("");
  const [planTitle, setplanTitle] = useState("");
  const [planDuration, setplanDuration] = useState(0);
  const [totalPayble, settotalPayble] = useState(0);
  const [customerName, setcustomerName] = useState("");
  const [customerEmail, setcustomerEmail] = useState("");
  const [customerAddress, setcustomerAddress] = useState("");
  const [customerLocation, setcustomerLocation] = useState("");

  const [orderDate, setorderDate] = useState(""); //editable
  const [dueDate, setdueDate] = useState(""); //editable

  const [paymentStatus, setpaymentStatus] = useState("");
  const [paymentAmount, setpaymentAmount] = useState("");
  const [paymentDate, setpaymentDate] = useState("");

  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(null);

  const invoiceItemTemplate = {
    _id: null,
    description: "",
    quantity: 0,
    price: 0,
    discountpercent: 0,
    discount: 0,
    taxpercent: 0,
    tax: 0,
    amount: 0,
    taxselectedvalue: null, // non-functional used for mapping react select
    descriptionwarning: false, // non-functional used for showing warning
    quantitywarning: false, // non-functional, used for showing warning
    pricewarning: false, // non-functional, used for showing warning
  };

  const [invoiceItemsList, setInvoiceItemsList] = useState([]);

  const [allowModification, setAllowModification] = useState(true);

  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);

  const [isSaving, setisSaving] = useState(false);

  const [savedInvoiceId, setSavedInvoiceId] = useState(null);

  // validation of invoice
  const [validation, setValidation] = useState({
    customerWarning: true,
    invoiceDateWarning: true,
    noteWarning: true,
  });

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all client list
  const getAllClientList = async () => {
    try {
      let clientRequestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST +
        `?token=${token}&isselect=${true}&slug=client`;

      const clientResponse = await getData(clientRequestUrl);

      console.log("getAllClientList", clientResponse);

      if (clientResponse.status) {
        setClientList(clientResponse.data);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //* function for get the invoice details
  const getInvoiceDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_INVOICE_DETAILS +
        `/${params.id}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log("invoice details ----->", response);

      if (response.status) {
        const invoiceData = response.data;

        setinvoiceIdNumber(invoiceData.invoiceid);
        setplanTitle(invoiceData.plantitle);
        setplanDuration(invoiceData.planduration);
        settotalPayble(invoiceData.totalpayble);

        setcustomerName(invoiceData.customername);
        setcustomerEmail(invoiceData.customeremail);
        setcustomerAddress(invoiceData.customeraddress);
        setcustomerLocation(invoiceData.customerlocation);

        setorderDate(invoiceData.invoicedate);
        setdueDate(invoiceData.duedate);

        setpaymentStatus(invoiceData.paymentstatus);
        setpaymentAmount(invoiceData.paymentamount);
        setpaymentDate(invoiceData.paymentdate);

        if (invoiceData.invoiceitems && invoiceData.invoiceitems.length > 0) {
          updateInvoiceBlock(invoiceData.invoiceitems);
        } else {
          settotalPayble(invoiceData.amount);
        }

        // disable modification on certain condition ---------
        if (invoiceData.plan) {
          setAllowModification(false);
        }

        // validation mapping false as if edit, we assume we already have customer and invoice data -----
        if (invoiceData.client) {
          setValidation((prevState) => ({
            ...prevState,
            customerWarning: false,
          }));
        }

        if (invoiceData.invoicedate) {
          setValidation((prevState) => ({
            ...prevState,
            invoiceDateWarning: false,
          }));
        }
      }
    } catch (error) {
      console.log("error", error.message);
    }
  };

  // on click of Add item
  const addNewInvoiceItem = () => {
    setInvoiceItemsList([...invoiceItemsList, invoiceItemTemplate]);
  };

  // on click delete a invoice item
  const onDeleteInvoiceItem = (index) => {
    const deletedItemBlock = [...invoiceItemsList];
    deletedItemBlock.splice(index, 1);
    setInvoiceItemsList(deletedItemBlock);
  };

  //update invoice item block
  const updateInvoiceBlock = (invoiceItems) => {
    const invoiceItemsArr = invoiceItems.map((invoiceItem) => {
      let invoiceItemSavedData = {
        _id: invoiceItem._id ?? null,
        description: invoiceItem.description ?? "",
        quantity: invoiceItem.unit ?? "",
        price: invoiceItem.price ?? "",
        discountpercent: invoiceItem.discountpercent ?? 0,
        discount: invoiceItem.discountamount ?? 0,
        taxpercent: invoiceItem.taxpercent ?? 0,
        tax: invoiceItem.taxamount ?? 0,
        amount: invoiceItem.totalprice ?? 0,
        taxselectedvalue: getTaxSelectedSelectOptionValue(
          invoiceItem.taxpercent
        ), // non-functional used for mapping react select
        descriptionwarning: false, // non-functional used for showing warning
        quantitywarning: false, // non-functional, used for showing warning
        pricewarning: false, // non-functional, used for showing warning
      };

      return invoiceItemSavedData;
    });

    setInvoiceItemsList(invoiceItemsArr);
  };

  //change invoice item input value
  const changeInvoiceItemsInputs = (index, field, value) => {
    const updatedFormValues = invoiceItemsList.map((block, i) => {
      if (field === "description") {
        if (value !== "") {
          block.descriptionwarning = false;
        } else {
          block.descriptionwarning = true;
        }
      } else if (field === "quantity") {
        if (value !== "") {
          block.quantitywarning = false;
        } else {
          block.quantitywarning = true;
        }
      } else if (field === "price") {
        if (value !== "") {
          block.pricewarning = false;
        } else {
          block.pricewarning = true;
        }
      } else {
      }
      return index == i ? Object.assign(block, { [field]: value }) : block;
    });

    setInvoiceItemsList(updatedFormValues);

    updateItemGrossVlues(index);
  };

  //function for select tax
  const taxSelectionHandler = (index, field1, field2, val) => {
    const updatedFormValues = invoiceItemsList.map((block, i) =>
      index == i
        ? Object.assign(block, { [field1]: val, [field2]: val.value })
        : block
    );

    setInvoiceItemsList(updatedFormValues);

    updateItemGrossVlues(index);
  };

  // update invoice calculation
  const invoiceItemUpdateHandler = () => {
    const updatedItems = [...invoiceItemsList];

    const totalPaybleAmount = updatedItems.reduce(
      (amount, item) => amount + item.price * item.quantity,
      0
    );

    const totalTax = updatedItems.reduce((tax, item) => tax + item.tax, 0);

    const totalDiscount = updatedItems.reduce(
      (disount, item) => disount + item.discount,
      0
    );

    const payableAmount = updatedItems.reduce(
      (totalamount, item) => totalamount + item.amount,
      0
    );

    settotalPayble(totalPaybleAmount);
    setTotalTax(totalTax);
    setTotalDiscount(totalDiscount);
    setPayableAmount(payableAmount);
  };

  // on update or view, show the pre-selected value of Tax for each invoice items
  const getTaxSelectedSelectOptionValue = (val) => {
    let selectedVatOption = {};

    for (let vatOption of VatOptions) {
      if (vatOption.value === val.toString()) {
        selectedVatOption = vatOption;
      }
    }

    return selectedVatOption;
  };

  // set dependent item value on params change
  const updateItemGrossVlues = (index) => {
    const updatedItems = [...invoiceItemsList];
    const quantity = parseInt(updatedItems[index].quantity);
    const price = parseInt(updatedItems[index].price);
    const tax = parseInt(updatedItems[index].taxpercent);

    if (quantity > 0 && price > 0) {
      const taxToPay = parseInt((quantity * price * tax) / 100, 10);
      const totalPrice = parseInt(quantity * price, 10) + taxToPay;

      updatedItems[index].tax = taxToPay;
      updatedItems[index].amount = totalPrice;

      setInvoiceItemsList(updatedItems);
    }
  };

  //function for select client
  const clientSelectionHandler = (val) => {
    setSelectedClient(val);
    setSelectedClientId(val.value);
  };

  // function for validation
  const validateForm = () => {
    let isValid = true;

    if (!params.id && selectedClientId === null) {
      isValid = false;
    }

    return isValid;
  };

  //* save the invoice record
  const saveInvoiceHandler = async () => {
    if (validateForm()) {
      setisSaving(true);

      let invoiceData = {
        leadid: null,
        contactid: null,
        clientid: selectedClientId,
        title: "",
        description: "",
        amount: totalPayble,
        promisepercent: 0,
        followers: [],
        invoicedate: orderDate,
        duedate: dueDate,
        totaltax: totalTax,
        totaldiscount: totalDiscount,
        payableamount: payableAmount,
        currency: "euro",
        items: invoiceItemsList,
        status: "1",
        notes: [],
        typeslug: "invoice",
        modulename: "",
      };

      console.log("invoice data ===>", invoiceData);

      try {
        let requestUrl = url.API_BASE_URL;

        let response = {};

        console.log("requestURL ", requestUrl);

        if (params.id) {
          requestUrl += url.API_UPDATE_INVOICE + `/${params.id}?token=${token}`;

          response = await putData(requestUrl, invoiceData);
        } else {
          requestUrl += url.API_CREATE_INVOICE + `?token=${token}`;

          response = await postData(requestUrl, invoiceData);
        }

        setisSaving(false);

        console.log("response ", response);

        if (response.status) {
          setSavedInvoiceId(response.data._id);
          setMessageType("success");

          setTimeout(() => {
            const bootstrapModal = new bootstrap.Modal(
              document.getElementById("afterSaveInvoiceModal")
            );
            bootstrapModal.show();
          }, 1000);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
      setShowAlert(true);
    } else {
    }
  };

  const afterSuccessModalClose = (responsType) => {
    if (responsType === "list") {
      history.push("/admin/invoice/list");
    } else {
      history.push(`/admin/invoice/details/${savedInvoiceId}`);
    }
  };

  // get client list and default UI
  useEffect(() => {
    getAllClientList();

    if (invoiceItemsList.length === 0 && !params.id) {
      addNewInvoiceItem();
    }
  }, []);

  // update cost and other info when any invoiceitem data changes in invoice
  useEffect(() => {
    if (invoiceItemsList.length > 0) {
      invoiceItemUpdateHandler();
    }
  }, [invoiceItemsList]);

  // get saved invoice data to show and update
  useEffect(() => {
    if (params.id) {
      getInvoiceDetails();
    }
  }, [params.id]);

  //close alert function
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_INVOICE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="invoice-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <BreadCrumb breadCrumbText={breadcrumbText} />

            <div className="row mt-3">
              <div
                className={`mb-4 mb-lg-0 ${
                  params.id ? "col-lg-8 " : "col-lg-12"
                }`}
              >
                <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
                  {/* Invoice sender and basic info */}
                  <div className="invoice mt-4">
                    <div className="d-flex align-items-center justify-content-between gap-3 mb-1">
                      <img
                        src={url.SERVER_URL + ecosystemLogo}
                        alt="FindelOI"
                        className="img-fluid"
                        style={{ maxHeight: "28px" }}
                      />
                    </div>
                    <div className="d-flex flex-column flex-sm-row">
                      {invoiceBillingAddress.address ? (
                        <p className="fs-md flex-fill mb-3">
                          <span className="d-block">
                            {invoiceBillingAddress.address}
                          </span>
                          <span className="d-block text-black">
                            {invoiceBillingAddress.supportmail}
                          </span>
                          <span className="d-block text-black">
                            {invoiceBillingAddress.phone}
                          </span>
                          <span className="d-block text-black">
                            {invoiceBillingAddress.website}
                          </span>
                        </p>
                      ) : (
                        <p className="fs-md flex-fill mb-3">
                          <span className="d-block">
                            Padre Piquer, 14, 28024, Madrid, Espafia
                          </span>
                          <span className="d-block text-black">
                            support@lobees.com
                          </span>
                          <span className="d-block text-black">
                            (+34) 615 43 94 22
                          </span>
                          <span className="d-block text-black">
                            www.lobees.com
                          </span>
                        </p>
                      )}

                      <ul className="flex-fill h3 d-flex flex-column gap-4 fw-semibold">
                        <li className="d-flex align-items-center gap-3">
                          <span className="d-block">
                            {t("Invoice Number")}:{" "}
                          </span>
                          <span className="d-block text-primary">
                            {invoiceIdNumber}
                          </span>
                        </li>

                        {planTitle === "" ? null : (
                          <li className="d-flex align-items-center gap-3">
                            <span className="d-block">
                              {t("Subscription Plan")}:{" "}
                            </span>
                            <span className="d-block text-primary">
                              {planTitle} ({planDuration} days)
                            </span>
                          </li>
                        )}

                        <li className="d-flex align-items-center gap-3">
                          <span className="d-block">
                            {t("Amount Payable")}:{" "}
                          </span>
                          <span className="d-block text-primary">
                            {totalPayble} €
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* Invoice details */}
                  <div className="p-3 border border-gray-300 rounded-8 mb-3">
                    <div className="row">
                      {/* Client or customer details */}
                      <div className="col-md mb-4 mb-md-0">
                        <h5 className="fw-semibold mb-2 mb-md-3">
                          {t("Customer Information")}
                        </h5>
                        {params.id ? null : (
                          <Select
                            options={clientList}
                            value={selectedClient}
                            onChange={(val) => {
                              setValidation((prevState) => ({
                                ...prevState,
                                customerWarning: false,
                              }));
                              clientSelectionHandler(val);
                            }}
                          />
                        )}

                        <p className="fs-sm mt-2">
                          <span className="d-block text-uppercase">
                            {customerName}{" "}
                          </span>
                          <span className="d-block text-black">
                            {customerEmail}
                          </span>
                          <span className="d-block text-black">
                            {customerAddress}
                          </span>
                          <span className="d-block text-black">
                            {customerLocation}
                          </span>
                        </p>
                      </div>

                      {/* Order or Invoice info */}
                      <div className="col-md mb-4 mb-md-0">
                        <h5 className="fw-semibold mb-2 mb-md-3">
                          {t("Order Information")}
                        </h5>
                        <div className="form-group d-flex align-items-center mb-2">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Invoice No")}:
                          </label>
                          {invoiceIdNumber === "" ? null : (
                            <p className="form-control px-3 py-2 shadow-none">
                              {invoiceIdNumber}
                            </p>
                          )}
                        </div>
                        <div className="form-group d-flex align-items-center mb-2">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold mb-2"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Order Date")}:
                          </label>
                          <input
                            type="date"
                            className="form-control px-3 py-2 shadow-none"
                            value={orderDate}
                            onChange={(e) => {
                              setorderDate(e.target.value);

                              setValidation((prevState) => ({
                                ...prevState,
                                invoiceDateWarning: false,
                              }));
                            }}
                          />
                        </div>
                        <div className="form-group d-flex align-items-center">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold mb-2"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Due Date")}:
                          </label>
                          <input
                            type="date"
                            className="form-control px-3 py-2 shadow-none"
                            value={dueDate}
                            onChange={(e) => {
                              setdueDate(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      {/* Payment info */}
                      <div className="col-md">
                        <h5 className="fw-semibold mb-2 mb-md-3">
                          {t("Payment Information")}
                        </h5>
                        <div className="form-group d-flex align-items-center mb-2">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Status")}:
                          </label>
                          <span className="d-block">{paymentStatus}</span>
                        </div>
                        <div className="form-group d-flex align-items-center my-4">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Amount")}:
                          </label>
                          <span className="d-block">{paymentAmount}</span>
                        </div>
                        <div className="form-group d-flex align-items-center mt-2">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Payment Date")}:
                          </label>
                          <span className="d-block">{paymentDate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Invoice Items */}
                  <div className="table-wrapper pb-5 mb-5 border-bottom border-gray">
                    <div className="table-responsive">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <thead>
                          <tr>
                            <th
                              width="40%"
                              className="description bg-transparent fs-md fw-semibold border-0"
                            >
                              {t("Description")}
                            </th>
                            <th className="quantity bg-transparent fs-md fw-semibold border-0">
                              {t("Quantity")}
                            </th>
                            <th className="amt bg-transparent fs-md fw-semibold border-0">
                              {t("Price")}
                            </th>
                            <th
                              width="20%"
                              className="taxes bg-transparent fs-md fw-semibold border-0"
                            >
                              {t("Tax (IVA)")}
                            </th>
                            <th className="pay-amt bg-transparent fs-md fw-semibold border-0">
                              {t("Total Amount")}
                            </th>
                            <th className="bg-transparent fs-md fw-semibold border-0"></th>
                          </tr>
                        </thead>
                        <tbody style={{ height: "200px" }}>
                          {invoiceItemsList.map((invoiceItem, index) => {
                            return (
                              <tr key={index} style={{ verticalAlign: "top" }}>
                                <td className="description border-bottom-0 p-1">
                                  <input
                                    type="text"
                                    name="description"
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.descriptionwarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    value={invoiceItem.description}
                                    onChange={(e) =>
                                      changeInvoiceItemsInputs(
                                        index,
                                        "description",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="quantity border-bottom-0 p-1">
                                  <input
                                    name="quantity"
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.quantitywarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    type="number"
                                    value={invoiceItem.quantity}
                                    onChange={(e) =>
                                      changeInvoiceItemsInputs(
                                        index,
                                        "quantity",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="amt border-bottom-0 p-1">
                                  <input
                                    name="price"
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.pricewarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                    type="number"
                                    value={invoiceItem.price}
                                    onChange={(e) =>
                                      changeInvoiceItemsInputs(
                                        index,
                                        "price",
                                        e.target.value
                                      )
                                    }
                                  />
                                </td>
                                <td className="taxes border-bottom-0 p-0">
                                  <Select
                                    placeholder="IVA %"
                                    options={VatOptions}
                                    className="form-control "
                                    value={invoiceItem.taxselectedvalue}
                                    onChange={(val) =>
                                      taxSelectionHandler(
                                        index,
                                        "taxselectedvalue",
                                        "taxpercent",
                                        val
                                      )
                                    }
                                  />
                                </td>
                                <td className="pay-amt border-bottom-0 p-1">
                                  <span
                                    className={`form-control bg-white shadow-none ${
                                      invoiceItem.pricewarning
                                        ? "border border-danger"
                                        : ""
                                    }`}
                                  >
                                    {invoiceItem.amount}
                                  </span>
                                </td>
                                <td className="action border-bottom-0">
                                  <Link
                                    className="text-black "
                                    to="#"
                                    onClick={() => onDeleteInvoiceItem(index)}
                                  >
                                    <span className="d-block material-symbols-outlined">
                                      delete
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <Link
                      to="#"
                      className={`btn btn-outline-primary ${
                        allowModification ? "" : "d-none"
                      }`}
                      onClick={() => {
                        addNewInvoiceItem();
                      }}
                    >
                      + {t("Add Item")}
                    </Link>

                    <button
                      className={`btn btn-primary ms-5 ${
                        allowModification ? "" : "d-none"
                      }`}
                      onClick={() => {
                        if (validateForm() === true) {
                          saveInvoiceHandler();
                        } else {
                          alert("client or invoice date is not selected");
                        }
                      }}
                      disabled={isSaving ? true : false}
                      style={{
                        cursor: isSaving ? "not-allowed" : "pointer",
                      }}
                    >
                      {t("Save Invoice")}

                      {isSaving && (
                        <div
                          className="mx-2 spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>

                  {/* Invoice final amount */}
                  <div className="table-wrapper pb-3 mb-3 border-bottom border-gray">
                    <div className="table-responsive">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <tbody>
                          <tr>
                            <td className="p-0 fw-semibold bg-transparent border-0">
                              {t("Total Discount")}
                            </td>
                            <td className="p-0 bg-transparent text-end border-0">
                              {totalDiscount}€
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0 fw-semibold bg-transparent border-0">
                              {t("Total Tax (VAT)")}
                            </td>
                            <td className="p-0 bg-transparent text-end border-0">
                              {totalTax}€
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0 fs-lg fw-semibold bg-transparent border-0">
                              {t("Total Payable Amount")}
                            </td>
                            <td className="p-0 fs-lg fw-semibold text-success bg-transparent text-end border-0">
                              {payableAmount}€
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <SaveInvoiceSuccessModal
                  moduleType="invoice"
                  afterSuccessModalClose={afterSuccessModalClose}
                />
              </div>
              {params.id ? (
                <div className="col-lg-4">
                  {" "}
                  <NoteSection
                    invoiceId={params.id}
                    setShowAlert={setShowAlert}
                    setAlertMessage={setAlertMessage}
                    setMessageType={setMessageType}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              alertType={messageType}
              message={alertMessage}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default InvoiceDetailsBody;
